import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Sidebar from "./components/Sidebar";
import HomeSlider from "./pages/HomeSlider";
import AddHomeSlider from "./pages/AddHomeSlider";
import ManageHomeSlider from "./pages/ManageHomeSlider";
import AddDevotion from "./pages/AddDevotion";
import ManageDevotion from "./pages/ManageDevotion";
import AddAdministrations from "./pages/AddAdministrations";
import ManageAdministrations from "./pages/ManageAdministrations";
import AddGallery from "./pages/AddGallery";
import ManageGallery from "./pages/ManageGallery";
import AddMember from "./pages/AddMember";
import ManageMember from "./pages/ManageMember";
import AddExMember from "./pages/AddExMember";
import ManageExMember from "./pages/ManageExMember";
import AddMagazine from "./pages/AddMagazine";
import ManageMagazine from "./pages/ManageMagazine";
import ManagePrayer from "./pages/ManagePrayer";
import ManageStory from "./pages/ManageStory";
import AddEvent from "./pages/AddEvent";
import ManageEvents from "./pages/ManageEvents";
import ContactUsDetails from "./pages/ContactUsDetails";
import Dashboard from "./pages/Dashboard";
import Login from "./pages/Login";

const App = () => {
  // Check if user is authenticated (you'll need to implement this based on your auth method)
  const isAuthenticated = () => {
    return localStorage.getItem("token") !== null; // Or your auth check logic
  };

  // Protected Route wrapper component
  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/login" replace />;
    }
    return (
      <div className="flex">
        <Sidebar />
        <div className="lg:ml-64 p-4 w-full">
          {children}
        </div>
      </div>
    );
  };
  
  return (
    <Router>
      <Routes>
        {/* Login route - will be the default route */}
        <Route path="/" element={<Navigate to="/login" replace />} />
        <Route path="/login" element={<Login />} />

        {/* Protected routes */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          path="/homeslider"
          element={
            <ProtectedRoute>
              <HomeSlider />
            </ProtectedRoute>
          }
        />
        <Route
          path="/homeslider/add"
          element={
            <ProtectedRoute>
              <AddHomeSlider />
            </ProtectedRoute>
          }
        />
        <Route
          path="/homeslider/manage"
          element={
            <ProtectedRoute>
              <ManageHomeSlider />
            </ProtectedRoute>
          }
        />
        <Route
          path="/devotion/add"
          element={
            <ProtectedRoute>
              <AddDevotion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/devotion/manage"
          element={
            <ProtectedRoute>
              <ManageDevotion />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrations/add"
          element={
            <ProtectedRoute>
              <AddAdministrations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/administrations/manage"
          element={
            <ProtectedRoute>
              <ManageAdministrations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gallery/add"
          element={
            <ProtectedRoute>
              <AddGallery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/gallery/manage"
          element={
            <ProtectedRoute>
              <ManageGallery />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members/add"
          element={
            <ProtectedRoute>
              <AddMember />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members/manage"
          element={
            <ProtectedRoute>
              <ManageMember />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members/add-ex"
          element={
            <ProtectedRoute>
              <AddExMember />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members/manage-ex"
          element={
            <ProtectedRoute>
              <ManageExMember />
            </ProtectedRoute>
          }
        />
        <Route
          path="/magazine/add"
          element={
            <ProtectedRoute>
              <AddMagazine />
            </ProtectedRoute>
          }
        />
        <Route
          path="/magazine/manage"
          element={
            <ProtectedRoute>
              <ManageMagazine />
            </ProtectedRoute>
          }
        />
        <Route
          path="/prayer/manage"
          element={
            <ProtectedRoute>
              <ManagePrayer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/story/manage"
          element={
            <ProtectedRoute>
              <ManageStory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events/add"
          element={
            <ProtectedRoute>
              <AddEvent />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events/manage"
          element={
            <ProtectedRoute>
              <ManageEvents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/contact/details"
          element={
            <ProtectedRoute>
              <ContactUsDetails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;