import React, { useState, useEffect } from 'react';

const ManageHomeSlider = () => {
  const [sliderItems, setSliderItems] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [editImage, setEditImage] = useState(null);
  const [editDescription, setEditDescription] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const baseURL = 'https://dubaichurch.xyz';
  const API_URL = `${baseURL}/homeslider`;

  const getImageUrl = (imagePath) => {
    if (!imagePath) return '';
    return `${baseURL}/uploads/${imagePath}`;
  };

  useEffect(() => {
    fetchSliderData();
  }, []);

  const fetchSliderData = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(API_URL);
      if (!response.ok) {
        throw new Error('Failed to fetch slider data');
      }
      const result = await response.json();
      setSliderItems(result.data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${API_URL}/${id}`, {
        method: 'DELETE',
      });
      
      if (!response.ok) {
        throw new Error('Failed to delete item');
      }
      
      fetchSliderData();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleEdit = (item) => {
    setIsEditing(item._id);
    setEditImage(null);  // Reset edit image
    setEditDescription(item.Description);
  };

  const handleUpdate = async (id) => {
    try {
      const formData = new FormData();
      if (editImage) {
        formData.append('SlideImage', editImage);
      }
      formData.append('Description', editDescription);

      const response = await fetch(`${API_URL}/${id}`, {
        method: 'PUT',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update item');
      }

      fetchSliderData();
      setIsEditing(null);
      setEditImage(null);
    } catch (err) {
      setError(err.message);
    }
  };

  if (isLoading) {
    return <div className="p-6">Loading...</div>;
  }

  if (error) {
    return <div className="p-6 text-red-500">Error: {error}</div>;
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md">
      <h2 className="text-2xl font-semibold mb-4">Manage Home Slider</h2>

      {error && <div className="mb-4 text-red-500">{error}</div>}

      <table className="w-full text-left border-collapse">
        <thead>
          <tr className="border-b">
            <th className="p-2">Image</th>
            <th className="p-2">Description</th>
            <th className="p-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {sliderItems.map((item) => (
            <tr key={item._id} className="border-b">
              <td className="p-2">
                {isEditing === item._id ? (
                  <div>
                    <input 
                      type="file" 
                      accept="image/*"
                      onChange={(e) => setEditImage(e.target.files[0])}
                      className="border border-gray-300 rounded p-1 mb-2"
                    />
                    <div>
                      <img 
                        src={getImageUrl(item.SlideImage)}
                        alt="Current" 
                        className="w-24 h-16 object-cover rounded"
                        crossOrigin="anonymous"
                      />
                    </div>
                  </div>
                ) : (
                  <div>
                    <img 
                      src={getImageUrl(item.SlideImage)}
                      alt="Slider" 
                      className="w-24 h-16 object-cover rounded"
                      crossOrigin="anonymous"
                    />
                  </div>
                )}
              </td>
              <td className="p-2">
                {isEditing === item._id ? (
                  <textarea 
                    value={editDescription}
                    onChange={(e) => setEditDescription(e.target.value)}
                    className="border border-gray-300 rounded p-1 w-full"
                    rows="3"
                  />
                ) : (
                  <div className="max-h-32 overflow-y-auto">
                    {item.Description}
                  </div>
                )}
              </td>
              <td className="p-2 space-x-2">
                {isEditing === item._id ? (
                  <>
                    <button 
                      onClick={() => handleUpdate(item._id)} 
                      className="px-3 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                    >
                      Update
                    </button>
                    <button 
                      onClick={() => {
                        setIsEditing(null);
                        setEditImage(null);
                      }} 
                      className="px-3 py-1 bg-gray-300 text-gray-700 rounded hover:bg-gray-400 transition"
                    >
                      Cancel
                    </button>
                  </>
                ) : (
                  <>
                    <button 
                      onClick={() => handleEdit(item)} 
                      className="px-3 py-1 bg-green-500 text-white rounded hover:bg-green-600 transition"
                    >
                      Edit
                    </button>
                    <button 
                      onClick={() => handleDelete(item._id)} 
                      className="px-3 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ManageHomeSlider;