import React, { useState } from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const ManageExMember = () => {
  const [members, setMembers] = useState([
    {
      id: 1,
      passcode: '123456',
      profilePicture: 'https://via.placeholder.com/100',
      email: 'john.doe@example.com',
      spouseName: 'Jane Doe',
      uaeAddress: '123 Dubai St, UAE',
      indianAddress: '456 Delhi St, India',
      name: 'John Doe',
      companyName: 'Tech Corp',
      childrenName: 'Anna, Mike',
      contactNumber1: '1234567890',
      contactNumber2: '0987654321',
      indiaPhoneNumber1: '919876543210',
      indiaPhoneNumber2: '919876543211',
    },
    {
      id: 2,
      passcode: '654321',
      profilePicture: 'https://via.placeholder.com/100',
      email: 'alex.smith@example.com',
      spouseName: 'Mary Smith',
      uaeAddress: '789 Abu Dhabi St, UAE',
      indianAddress: '123 Mumbai St, India',
      name: 'Alex Smith',
      companyName: 'Dev Solutions',
      childrenName: 'Liam, Emma',
      contactNumber1: '9876543210',
      contactNumber2: '1234567890',
      indiaPhoneNumber1: '917654321098',
      indiaPhoneNumber2: '917654321099',
    },
  ]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editMember, setEditMember] = useState(null);

  const handleEdit = (member) => {
    setEditMember({ ...member });
    setIsModalOpen(true);
  };

  const handleDelete = (id) => {
    const updatedMembers = members.filter((member) => member.id !== id);
    setMembers(updatedMembers);
    alert(`Member with ID: ${id} has been deleted.`);
  };

  const handleModalChange = (e) => {
    const { name, value } = e.target;
    setEditMember((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdate = () => {
    const updatedMembers = members.map((member) =>
      member.id === editMember.id ? editMember : member
    );
    setMembers(updatedMembers);
    setIsModalOpen(false);
    alert('Member details updated.');
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Manage Ex Members</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full table-auto border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-4 py-2 border">Name</th>
              <th className="px-4 py-2 border">passcode</th>
              <th className="px-4 py-2 border">Name</th>
              <th className="px-4 py-2 border">Email</th>
              <th className="px-4 py-2 border">Spouse</th>
              <th className="px-4 py-2 border">Company</th>
              <th className="px-4 py-2 border">Contact Numbers</th>
              <th className="px-4 py-2 border">Actions</th>
            </tr>
          </thead>
          <tbody>
            {members.map((member) => (
              <tr key={member.id} className="border-b">
                <td className="px-4 py-2 border">{member.name}</td>
                <td className="px-4 py-2 border">{member.passcode}</td>
                <td className="px-4 py-2 border">{member.name}</td>
                <td className="px-4 py-2 border">{member.email}</td>
                <td className="px-4 py-2 border">{member.spouseName}</td>
                <td className="px-4 py-2 border">{member.companyName}</td>
                <td className="px-4 py-2 border">
                  {member.contactNumber1} | {member.contactNumber2} <br/> {member.indiaPhoneNumber1} | {member.indiaPhoneNumber2}
                </td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => handleEdit(member)}
                    className="bg-yellow-500 text-white px-4 py-2 rounded-md mr-2"
                  >
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => handleDelete(member.id)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                  >
                    <FaTrashAlt />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Edit Member Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white p-6 rounded-md w-3/4 md:w-1/2">
            <h2 className="text-2xl font-semibold mb-4">Edit Member</h2>
            <form>
              <div className="grid grid-cols-2 gap-4 mb-4">
                {/* Row 1 */}
                <div>
                  <label htmlFor="name" className="block text-gray-700">Name</label>
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={editMember.name}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="email" className="block text-gray-700">Email</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={editMember.email}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row 2 */}
                <div>
                  <label htmlFor="spouseName" className="block text-gray-700">Passcode</label>
                  <input
                    type="number"
                    id="passcode"
                    name="passcode"
                    value={editMember.passcode}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="spouseName" className="block text-gray-700">Spouse Name</label>
                  <input
                    type="text"
                    id="spouseName"
                    name="spouseName"
                    value={editMember.spouseName}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="companyName" className="block text-gray-700">Company Name</label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={editMember.companyName}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row 3 */}
                <div>
                  <label htmlFor="contactNumber1" className="block text-gray-700">Contact Number 1</label>
                  <input
                    type="text"
                    id="contactNumber1"
                    name="contactNumber1"
                    value={editMember.contactNumber1}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="contactNumber2" className="block text-gray-700">Contact Number 2</label>
                  <input
                    type="text"
                    id="contactNumber2"
                    name="contactNumber2"
                    value={editMember.contactNumber2}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
                <div>
                  <label htmlFor="indiaPhoneNumber1" className="block text-gray-700">India Contact Number 1</label>
                  <input
                    type="text"
                    id="indiaPhoneNumber1"
                    name="indiaPhoneNumber1"
                    value={editMember.indiaPhoneNumber1}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="indiaPhoneNumber2" className="block text-gray-700">India Contact Number 2</label>
                  <input
                    type="text"
                    id="indiaPhoneNumber2"
                    name="indiaPhoneNumber2"
                    value={editMember.indiaPhoneNumber2}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row 4 */}
                <div>
                  <label htmlFor="uaeAddress" className="block text-gray-700">UAE Address</label>
                  <input
                    type="text"
                    id="uaeAddress"
                    name="uaeAddress"
                    value={editMember.uaeAddress}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="indianAddress" className="block text-gray-700">Indian Address</label>
                  <input
                    type="text"
                    id="indianAddress"
                    name="indianAddress"
                    value={editMember.indianAddress}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                {/* Row 5 */}
                <div>
                  <label htmlFor="childrenName" className="block text-gray-700">Children's Names</label>
                  <input
                    type="text"
                    id="childrenName"
                    name="childrenName"
                    value={editMember.childrenName}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>

                <div>
                  <label htmlFor="profilePicture" className="block text-gray-700">Profile Picture URL</label>
                  <input
                    type="text"
                    id="profilePicture"
                    name="profilePicture"
                    value={editMember.profilePicture}
                    onChange={handleModalChange}
                    className="w-full p-2 border border-gray-300 rounded-md"
                  />
                </div>
              </div>

              <div className="flex justify-end space-x-2">
                <button
                  type="button"
                  onClick={() => setIsModalOpen(false)}
                  className="bg-gray-300 text-white px-4 py-2 rounded-md"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleUpdate}
                  className="bg-blue-500 text-white px-4 py-2 rounded-md"
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageExMember;
