import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManagePrayer = () => {
  const [prayers, setPrayers] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedPrayer, setSelectedPrayer] = useState(null);

  useEffect(() => {
    fetchPrayerRequests();
  }, []);

  const fetchPrayerRequests = async () => {
    try {
      const response = await axios.get('https://dubaichurch.xyz/prayer');
      if (Array.isArray(response.data.data)) {
        setPrayers(response.data.data);
      } else {
        console.error('Error: response.data is not an array');
      }
    } catch (error) {
      console.error('Error fetching prayer requests:', error);
    }
  };

  const handleDelete = async (_id) => {
    try {
      await axios.delete(`https://dubaichurch.xyz/prayer/${_id}`);
      fetchPrayerRequests();
      setIsDeleteModalOpen(false);
    } catch (error) {
      console.error('Error deleting prayer request:', error);
      console.error('Error response:', error.response);
      alert('There was an error deleting the prayer request. Please try again later.');
    }
  };

  const handleOpenDeleteModal = (prayer) => {
    setSelectedPrayer(prayer);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedPrayer(null);
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-semibold mb-4">Manage Prayer Requests</h2>
      <table className="min-w-full table-auto">
        <thead>
          <tr>
            <th className="px-4 py-2 border">Name</th>
            <th className="px-4 py-2 border">Email</th>
            <th className="px-4 py-2 border">Mobile Number</th>
            <th className="px-4 py-2 border">Prayer Request</th>
            <th className="px-4 py-2 border">Actions</th>
          </tr>
        </thead>
        <tbody>
          {Array.isArray(prayers) && prayers.length > 0 ? (
            prayers.map((prayer) => (
              <tr key={prayer.id}>
                <td className="px-4 py-2 border">{prayer.Name}</td>
                <td className="px-4 py-2 border">{prayer.Email}</td>
                <td className="px-4 py-2 border">{prayer.MobileNumber}</td>
                <td className="px-4 py-2 border">{prayer.PrayerRequest}</td>
                <td className="px-4 py-2 border">
                  <button
                    onClick={() => handleOpenDeleteModal(prayer)}
                    className="bg-red-500 text-white px-4 py-2 rounded-md"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} className="px-4 py-2 border">
                No prayer requests found.
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {isDeleteModalOpen && selectedPrayer && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg w-96">
            <h3 className="text-lg font-semibold mb-4">Delete Prayer Request</h3>
            <p>Are you sure you want to delete the prayer request for {selectedPrayer.Name}?</p>
            <div className="flex justify-end space-x-2 mt-4">
              <button
                type="button"
                onClick={handleCloseDeleteModal}
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
              <button
                type="button"
                onClick={() => handleDelete(selectedPrayer._id)}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ManagePrayer;