import React from 'react';

const Dashboard = () => {
  const stats = [
    { title: 'Total Enquiries', count: 150, icon: '📞', bgColor: 'bg-blue-500' },
    { title: 'Contact Us Messages', count: 75, icon: '💬', bgColor: 'bg-green-500' },
    { title: 'Pending Orders', count: 10, icon: '⏳', bgColor: 'bg-yellow-500' },
    { title: 'Active Users', count: 230, icon: '👥', bgColor: 'bg-purple-500' },
  ];

  return (
    <div className="min-h-screen bg-gray-100 py-8 px-6">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-8">Dashboard</h1>
      
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6">
        {stats.map((stat, index) => (
          <div
            key={index}
            className={`p-6 rounded-lg shadow-lg ${stat.bgColor} text-white flex items-center justify-between`}
          >
            <div>
              <p className="text-xl font-semibold">{stat.title}</p>
              <p className="text-2xl font-bold mt-2">{stat.count}</p>
            </div>
            <div className="text-4xl">{stat.icon}</div>
          </div>
        ))}
      </div>

      <div className="mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {/* Example Cards for more information or action */}
        <div className="p-6 rounded-lg bg-white shadow-lg">
          <h3 className="text-xl font-semibold text-gray-800">Recent Activity</h3>
          <p className="text-gray-600 mt-2">View recent activities here</p>
          <button className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition duration-200">
            View All
          </button>
        </div>
        <div className="p-6 rounded-lg bg-white shadow-lg">
          <h3 className="text-xl font-semibold text-gray-800">System Stats</h3>
          <p className="text-gray-600 mt-2">View system health and usage</p>
          <button className="mt-4 bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-200">
            View Stats
          </button>
        </div>
        <div className="p-6 rounded-lg bg-white shadow-lg">
          <h3 className="text-xl font-semibold text-gray-800">Admin Tasks</h3>
          <p className="text-gray-600 mt-2">Manage your admin tasks here</p>
          <button className="mt-4 bg-yellow-500 text-white py-2 px-4 rounded-lg hover:bg-yellow-600 transition duration-200">
            Manage Tasks
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
