import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ManageAdministrations = () => {
  const [administrations, setAdministrations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editData, setEditData] = useState({
    _id: null,
    Name: '',
    Title: '',
    AdminstarionImage: null,
  });

  // Base URL for API calls
  const baseURL = 'https://dubaichurch.xyz';

  // Function to get image URL with credentials
  const getImageUrl = (imagePath) => {
    return new URL(`/uploads/${imagePath}`, baseURL).href;
  };

  // Fetch administrations
  const fetchAdministrations = async () => {
    try {
      const response = await axios.get(`${baseURL}/administrations`);
      setAdministrations(response.data.data);
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdministrations();
  }, []);

  const handleEdit = (admin) => {
    setEditData({
      _id: admin._id,
      Name: admin.Name,
      Title: admin.Title,
      AdminstarionImage: null
    });
  };

  const handleDelete = async (id) => {
    if (window.confirm('Are you sure you want to delete this administration?')) {
      try {
        await axios.delete(`${baseURL}/administrations/${id}`);
        fetchAdministrations();
      } catch (err) {
        setError(err.response?.data?.message || err.message);
      }
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append('Name', editData.Name);
      formData.append('Title', editData.Title);
      if (editData.AdminstarionImage) {
        formData.append('AdminstarionImage', editData.AdminstarionImage);
      }

      await axios.put(
        `${baseURL}/administrations/${editData._id}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      fetchAdministrations();
      setEditData({
        _id: null,
        Name: '',
        Title: '',
        AdminstarionImage: null,
      });
    } catch (err) {
      setError(err.response?.data?.message || err.message);
    }
  };

  if (loading) return <div className="text-center p-4">Loading...</div>;
  if (error) return <div className="text-red-500 text-center p-4">{error}</div>;

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Manage Administrations</h1>

      <table className="min-w-full bg-white border border-gray-300 rounded-lg shadow-md">
        <thead className="bg-gray-800 text-white">
          <tr>
            <th className="px-4 py-2">Image</th>
            <th className="px-4 py-2">Name</th>
            <th className="px-4 py-2">Title</th>
            <th className="px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          {administrations.map((admin) => (
            <tr key={admin._id} className="border-b hover:bg-gray-100">
              <td className="px-4 py-2">
                <img
                  src={getImageUrl(admin.AdminstarionImage)}
                  alt={admin.Name}
                  className="w-16 h-16 object-cover rounded-full"
                  crossOrigin="anonymous"
                />
              </td>
              <td className="px-4 py-2">{admin.Name}</td>
              <td className="px-4 py-2">{admin.Title}</td>
              <td className="px-4 py-2">
                <button
                  onClick={() => handleEdit(admin)}
                  className="bg-blue-500 text-white py-1 px-3 rounded-md mr-2 hover:bg-blue-600"
                >
                  Edit
                </button>
                <button
                  onClick={() => handleDelete(admin._id)}
                  className="bg-red-500 text-white py-1 px-3 rounded-md hover:bg-red-600"
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editData._id && (
        <div className="mt-8">
          <h2 className="text-xl font-semibold">Edit Administration</h2>
          <form
            onSubmit={handleUpdate}
            className="bg-white p-6 rounded-lg shadow-md mt-4"
          >
            <div className="mb-4">
              <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
              <input
                type="text"
                id="name"
                value={editData.Name}
                onChange={(e) => setEditData({ ...editData, Name: e.target.value })}
                className="mt-2 block w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">Title</label>
              <input
                type="text"
                id="title"
                value={editData.Title}
                onChange={(e) => setEditData({ ...editData, Title: e.target.value })}
                className="mt-2 block w-full px-4 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                required
              />
            </div>

            <div className="mb-4">
              <label htmlFor="image" className="block text-sm font-medium text-gray-700">Image</label>
              <input
                type="file"
                id="image"
                onChange={(e) => setEditData({ ...editData, AdminstarionImage: e.target.files[0] })}
                accept="image/*"
                className="mt-2 block w-full text-sm text-gray-500 file:py-2 file:px-4 file:border file:rounded-md file:bg-gray-100 file:text-gray-700 file:cursor-pointer"
              />
            </div>

            <div className="flex gap-4">
              <button
                type="submit"
                className="flex-1 py-2 bg-green-600 text-white rounded-md hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500"
              >
                Update Administration
              </button>
              <button
                type="button"
                onClick={() => setEditData({ _id: null, Name: '', Title: '', AdminstarionImage: null })}
                className="flex-1 py-2 bg-gray-500 text-white rounded-md hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ManageAdministrations;