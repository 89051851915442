import React, { useState } from 'react';

const AddExMember = () => {
  const [memberData, setMemberData] = useState({
    passcode: '',
    profilePicture: null,
    email: '',
    spouseName: '',
    uaeAddress: '',
    indianAddress: '',
    name: '',
    companyName: '',
    childrenName: '',
    contactNumber1: '',
    contactNumber2: '',
    indiaPhoneNumber1: '',
    indiaPhoneNumber2: '',
  });

  const [imagePreview, setImagePreview] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setMemberData((prevData) => ({
      ...prevData,
      profilePicture: file,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle the form submission
    console.log('Submitted member data:', memberData);
    alert('Member added successfully!');
    setMemberData({
      passcode: '',
      profilePicture: null,
      email: '',
      spouseName: '',
      uaeAddress: '',
      indianAddress: '',
      name: '',
      companyName: '',
      childrenName: '',
      contactNumber1: '',
      contactNumber2: '',
      indiaPhoneNumber1: '',
      indiaPhoneNumber2: '',
    });
    setImagePreview(null);
  };

  return (
    <div className="container mx-auto p-8">
      <h1 className="text-3xl font-bold mb-6">Add Ex Member</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name & Passcode */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={memberData.name}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="passcode" className="block text-sm font-medium text-gray-700">Passcode</label>
            <input
              type="text"
              id="passcode"
              name="passcode"
              value={memberData.passcode}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        {/* Email Address & Spouse Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={memberData.email}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="spouseName" className="block text-sm font-medium text-gray-700">Spouse Name</label>
            <input
              type="text"
              id="spouseName"
              name="spouseName"
              value={memberData.spouseName}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* UAE Address & Indian Address */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="uaeAddress" className="block text-sm font-medium text-gray-700">UAE Address</label>
            <textarea
              id="uaeAddress"
              name="uaeAddress"
              value={memberData.uaeAddress}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            ></textarea>
          </div>
          <div>
            <label htmlFor="indianAddress" className="block text-sm font-medium text-gray-700">Indian Address</label>
            <textarea
              id="indianAddress"
              name="indianAddress"
              value={memberData.indianAddress}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            ></textarea>
          </div>
        </div>

        {/* Company Name & Children Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">Company Name</label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={memberData.companyName}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="childrenName" className="block text-sm font-medium text-gray-700">Children Name</label>
            <input
              type="text"
              id="childrenName"
              name="childrenName"
              value={memberData.childrenName}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Profile Picture */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="profilePicture" className="block text-sm font-medium text-gray-700">Profile Picture</label>
            <input
              type="file"
              id="profilePicture"
              name="profilePicture"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            {imagePreview && (
              <div className="mt-4">
                <img src={imagePreview} alt="Profile Preview" className="w-32 h-32 object-cover rounded-full" />
              </div>
            )}
          </div>
        </div>

        {/* Contact Number (Two Fields in One Row) */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="contactNumber1" className="block text-sm font-medium text-gray-700">Contact Number 1</label>
            <input
              type="text"
              id="contactNumber1"
              name="contactNumber1"
              value={memberData.contactNumber1}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="contactNumber2" className="block text-sm font-medium text-gray-700">Contact Number 2</label>
            <input
              type="text"
              id="contactNumber2"
              name="contactNumber2"
              value={memberData.contactNumber2}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* India Contact Number (Two Fields in One Row) */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="indiaPhoneNumber1" className="block text-sm font-medium text-gray-700">India Phone Number 1</label>
            <input
              type="text"
              id="indiaPhoneNumber1"
              name="indiaPhoneNumber1"
              value={memberData.indiaPhoneNumber1}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="indiaPhoneNumber2" className="block text-sm font-medium text-gray-700">India Phone Number 2</label>
            <input
              type="text"
              id="indiaPhoneNumber2"
              name="indiaPhoneNumber2"
              value={memberData.indiaPhoneNumber2}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddExMember;
