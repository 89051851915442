import React, { useState } from 'react';
import axios from 'axios';

const AddEvent = () => {
  const [newEvent, setNewEvent] = useState({
    Title: '',
    Eventdate: '',
    Time: '',
    Day: '',
    Venu: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewEvent({ ...newEvent, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post('https://dubaichurch.xyz/Events', newEvent);
      console.log('New Event Added:', response.data.data);
      setNewEvent({ Title: '', Eventdate: '', Time: '', Day: '', Venu: '' });
    } catch (error) {
      console.error('Error adding event:', error);
    }
  };

  return (
    <div className="max-w-6xl mx-auto p-8 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Add New Event</h2>

      <div className="p-6 bg-gray-100 rounded-lg shadow-md">
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-lg font-medium text-gray-800">Event Title:</label>
              <input
                type="text"
                name="Title"
                value={newEvent.Title}
                onChange={handleChange}
                className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                placeholder="Enter event Title"
                required
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-gray-800">Eventdate:</label>
              <input
                type="date"
                name="Eventdate"
                value={newEvent.Eventdate}
                onChange={handleChange}
                className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                required
              />
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-lg font-medium text-gray-800">Event Time:</label>
              <input
                type="time"
                name="Time"
                value={newEvent.Time}
                onChange={handleChange}
                className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                required
              />
            </div>
            <div>
              <label className="block text-lg font-medium text-gray-800">Event Day:</label>
              <input
                type="text"
                name="Day"
                value={newEvent.Day}
                onChange={handleChange}
                className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                placeholder="Enter event Day"
                required
              />
            </div>
          </div>

          <div>
            <label className="block text-lg font-medium text-gray-800">Event Venu:</label>
            <input
              type="text"
              name="Venu"
              value={newEvent.Venu}
              onChange={handleChange}
              className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
              placeholder="Enter event Venu"
              required
            />
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none"
            >
              Add Event
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEvent;