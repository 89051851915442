import React, { useState, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const ManageDevotion = () => {
  const [devotions, setDevotions] = useState([]);
  const [editDevotion, setEditDevotion] = useState(null);
  const [editedTitle, setEditedTitle] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [editedDate, setEditedDate] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // Fetch devotions data
  const fetchDevotions = async () => {
    try {
      const response = await fetch('https://dubaichurch.xyz/daily-devotion');
      const result = await response.json();
      
      if (!response.ok) {
        throw new Error(result.message || 'Failed to fetch devotions');
      }
      
      setDevotions(result.data);
    } catch (err) {
      setError('Failed to load devotions: ' + err.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDevotions();
  }, []);

  // Parse date string to Date object
  const parseDate = (dateString) => {
    if (!dateString) return new Date();
    
    // Check if date is in DD-MM-YYYY format
    const ddmmyyyyFormat = /^\d{2}-\d{2}-\d{4}$/;
    if (ddmmyyyyFormat.test(dateString)) {
      const [day, month, year] = dateString.split('-');
      return new Date(year, month - 1, day);
    }
    
    // Handle YYYY-MM-DD format
    const yyyymmddFormat = /^\d{4}-\d{2}-\d{2}$/;
    if (yyyymmddFormat.test(dateString)) {
      return new Date(dateString);
    }
    
    return new Date();
  };

  // Format date for API
  const formatDateForAPI = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  // Format date for display
  const formatDateForDisplay = (dateString) => {
    try {
      const date = parseDate(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}-${month}-${year}`;
    } catch (err) {
      return dateString; // Return original string if parsing fails
    }
  };

  // Handle delete functionality
  const handleDelete = async (id) => {
    try {
      const response = await fetch(`https://dubaichurch.xyz/daily-devotion/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error('Failed to delete devotion');
      }

      setSuccessMessage('Devotion deleted successfully');
      fetchDevotions(); // Refresh the list
    } catch (err) {
      setError('Failed to delete devotion: ' + err.message);
    }
  };

  // Handle edit functionality
  const handleEdit = (devotion) => {
    setEditDevotion(devotion);
    setEditedTitle(devotion.Title);
    setEditedDescription(devotion.Description);
    setEditedDate(parseDate(devotion.Date));
  };

  // Handle update functionality
  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      const formattedDate = formatDateForAPI(editedDate);
      
      const response = await fetch(`https://dubaichurch.xyz/daily-devotion/${editDevotion._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          Title: editedTitle,
          Description: editedDescription,
          Date: formattedDate,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update devotion');
      }

      setSuccessMessage('Devotion updated successfully');
      fetchDevotions(); // Refresh the list
      setEditDevotion(null); // Close edit form
    } catch (err) {
      setError('Failed to update devotion: ' + err.message);
    }
  };

  if (isLoading) {
    return (
      <div className="p-6 text-center">
        <p>Loading devotions...</p>
      </div>
    );
  }

  return (
    <div className="p-6 bg-white rounded-lg shadow-md max-w-4xl mx-auto">
      <h2 className="text-2xl font-semibold mb-6 border-b pb-4">Manage Devotions</h2>

      {error && (
        <div className="mb-4 p-4 bg-red-50 border border-red-200 rounded-lg text-red-600">
          {error}
        </div>
      )}

      {successMessage && (
        <div className="mb-4 p-4 bg-green-50 border border-green-200 rounded-lg text-green-600">
          {successMessage}
        </div>
      )}

      {editDevotion ? (
        // Edit Form
        <form onSubmit={handleUpdate} className="mb-6">
          <div className="mb-4">
            <label className="block text-gray-600 font-medium mb-2">Title</label>
            <input
              type="text"
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              className="border border-gray-300 rounded-lg p-2 w-full"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 font-medium mb-2">Description</label>
            <textarea
              value={editedDescription}
              onChange={(e) => setEditedDescription(e.target.value)}
              className="border border-gray-300 rounded-lg p-2 w-full"
              rows="4"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-gray-600 font-medium mb-2">Date</label>
            <DatePicker
              selected={editedDate}
              onChange={(date) => setEditedDate(date)}
              className="border border-gray-300 rounded-lg p-2 w-full"
              dateFormat="dd-MM-yyyy"
              required
            />
          </div>
          <div className="flex gap-2">
            <button
              type="submit"
              className="bg-blue-500 text-white px-6 py-2 rounded-md hover:bg-blue-600 transition"
            >
              Update
            </button>
            <button
              type="button"
              onClick={() => setEditDevotion(null)}
              className="bg-gray-500 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition"
            >
              Cancel
            </button>
          </div>
        </form>
      ) : (
        // Devotions Table
        <div className="overflow-x-auto">
          <table className="min-w-full table-auto">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-4 py-2 border-b text-left">Date</th>
                <th className="px-4 py-2 border-b text-left">Title</th>
                <th className="px-4 py-2 border-b text-left">Description</th>
                <th className="px-4 py-2 border-b text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {devotions.map((devotion) => (
                <tr key={devotion._id} className="hover:bg-gray-50">
                  <td className="px-4 py-2 border-b">{formatDateForDisplay(devotion.Date)}</td>
                  <td className="px-4 py-2 border-b">{devotion.Title}</td>
                  <td className="px-4 py-2 border-b">{devotion.Description}</td>
                  <td className="px-4 py-2 border-b text-center">
                    <button
                      onClick={() => handleEdit(devotion)}
                      className="bg-yellow-500 text-white px-4 py-2 rounded-md mr-2 hover:bg-yellow-600 transition"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(devotion._id)}
                      className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 transition"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ManageDevotion;