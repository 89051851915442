import { useState } from "react";
import { NavLink } from "react-router-dom";
import { 
  LayoutDashboard, 
  Home, 
  Book, 
  Users, 
  FileImage, 
  Calendar,
  Newspaper,
  Mail,
  ChevronDown,
  ChevronUp,
  Menu,
  UserCog,
  BookOpen,
  MessageCircle,
  LogOut
} from "lucide-react";

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [openMenu, setOpenMenu] = useState('');

  const toggleSidebar = () => setIsOpen(!isOpen);
  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? '' : menu);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/login';
  };

  const menuItems = [
    {
      title: 'Dashboard',
      icon: <LayoutDashboard size={20} />,
      path: '/dashboard',
      single: true
    },
    {
      title: 'HomeSlider',
      icon: <Home size={20} />,
      items: [
        { title: 'Add HomeSlider', path: '/homeslider/add' },
        { title: 'Manage HomeSlider', path: '/homeslider/manage' }
      ]
    },
    {
      title: 'Devotion',
      icon: <Book size={20} />,
      items: [
        { title: 'Add Devotion', path: '/devotion/add' },
        { title: 'Manage Devotion', path: '/devotion/manage' }
      ]
    },
    {
      title: 'Administrations',
      icon: <UserCog size={20} />,
      items: [
        { title: 'Add Administration', path: '/administrations/add' },
        { title: 'Manage Administration', path: '/administrations/manage' }
      ]
    },
    {
      title: 'Gallery',
      icon: <FileImage size={20} />,
      items: [
        { title: 'Add Gallery', path: '/gallery/add' },
        { title: 'Manage Gallery', path: '/gallery/manage' }
      ]
    },
    {
      title: 'Members',
      icon: <Users size={20} />,
      items: [
        { title: 'Add Member', path: '/members/add' },
        { title: 'Manage Member', path: '/members/manage' },
        { title: 'Add Ex Member', path: '/members/add-ex' },
        { title: 'Manage Ex Member', path: '/members/manage-ex' }
      ]
    },
    {
      title: 'Magazine',
      icon: <Newspaper size={20} />,
      items: [
        { title: 'Add Magazine', path: '/magazine/add' },
        { title: 'Manage Magazine', path: '/magazine/manage' }
      ]
    },
    {
      title: 'Prayer',
      icon: <BookOpen size={20} />,
      items: [
        { title: 'Manage Prayer', path: '/prayer/manage' }
      ]
    },
    {
      title: 'Events',
      icon: <Calendar size={20} />,
      items: [
        { title: 'Add Events', path: '/events/add' },
        { title: 'Manage Events', path: '/events/manage' }
      ]
    },
    {
      title: 'Story',
      icon: <MessageCircle size={20} />,
      items: [
        { title: 'Manage Story', path: '/story/manage' }
      ]
    },
    {
      title: 'Contact Us',
      icon: <Mail size={20} />,
      items: [
        { title: 'Contact Details', path: '/contact/details' }
      ]
    }
  ];

  const MenuItem = ({ item }) => {
    if (item.single) {
      return (
        <NavLink 
          to={item.path}
          className={({ isActive }) =>
            `flex items-center px-4 py-3 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-200 ${
              isActive ? 'bg-gray-700 text-white' : ''
            }`
          }
        >
          {item.icon}
          <span className="ml-3">{item.title}</span>
        </NavLink>
      );
    }

    return (
      <div className="mb-1">
        <button
          onClick={() => toggleMenu(item.title)}
          className="w-full flex items-center justify-between px-4 py-3 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-200"
        >
          <div className="flex items-center">
            {item.icon}
            <span className="ml-3">{item.title}</span>
          </div>
          {openMenu === item.title ? 
            <ChevronUp size={16} /> : 
            <ChevronDown size={16} />
          }
        </button>
        <div className={`overflow-hidden transition-all duration-300 ${openMenu === item.title ? 'max-h-screen' : 'max-h-0'}`}>
          {item.items.map((subItem, index) => (
            <NavLink
              key={index}
              to={subItem.path}
              className={({ isActive }) =>
                `block pl-12 pr-4 py-2 text-sm text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-200 ${
                  isActive ? 'bg-gray-700 text-white' : ''
                }`
              }
            >
              {subItem.title}
            </NavLink>
          ))}
        </div>
      </div>
    );
  };

  return (
    <>
      <button 
        className="fixed top-4 left-4 z-50 md:hidden p-2 rounded-md bg-gray-800 text-white"
        onClick={toggleSidebar}
      >
        <Menu size={24} />
      </button>

      <div className={`fixed top-0 left-0 h-full bg-gray-900 transition-transform duration-300 ${
        isOpen ? 'translate-x-0' : '-translate-x-full'
      } md:translate-x-0 w-64 shadow-lg overflow-hidden`}>
        
        {/* Logo Section */}
        <div className="flex flex-col items-center justify-center h-20">
          <img
            src="https://salemagdubai.com/wp-content/uploads/2024/08/salem-logo-H.png"
            alt="Logo"
            className="h-10 w-auto"
          />
        </div>

        {/* Navigation with Scrollable Area */}
        <div className="mt-2 flex-1 overflow-y-auto max-h-[calc(100vh-120px)]">
          {menuItems.map((item, index) => (
            <MenuItem key={index} item={item} />
          ))}
        </div>

        {/* Logout Button */}
        <div className="w-full">
          <button 
            onClick={handleLogout} 
            className="flex items-center w-full px-4 py-3 text-gray-300 hover:bg-gray-700 hover:text-white transition-colors duration-200"
          >
            <LogOut size={20} />
            <span className="ml-3">Logout</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
