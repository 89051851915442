import React, { useState } from 'react';
import axios from 'axios';

const ManageEvents = () => {
  const [events, setEvents] = useState([]);
  const [isEditing, setIsEditing] = useState(null);
  const [currentEvent, setCurrentEvent] = useState({});

  const fetchEvents = async () => {
    try {
      const response = await axios.get('https://dubaichurch.xyz/Events');
      setEvents(response.data.data);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };

  const handleEdit = (event) => {
    setIsEditing(event._id);
    setCurrentEvent(event);
  };

  const handleUpdate = async () => {
    try {
      await axios.put(`https://dubaichurch.xyz/Events/${currentEvent._id}`, currentEvent);
      const updatedEvents = events.map((event) =>
        event._id === currentEvent._id ? currentEvent : event
      );
      setEvents(updatedEvents);
      setIsEditing(null);
    } catch (error) {
      console.error('Error updating event:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`https://dubaichurch.xyz/Events/${id}`);
      const filteredEvents = events.filter((event) => event._id !== id);
      setEvents(filteredEvents);
    } catch (error) {
      console.error('Error deleting event:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentEvent({ ...currentEvent, [name]: value });
  };

  React.useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <div className="max-w-6xl mx-auto p-8 bg-white rounded-lg shadow-lg space-y-8">
      <h2 className="text-3xl font-bold text-gray-800 text-center mb-6">Manage Events</h2>

      {isEditing ? (
        <div className="p-6 bg-gray-100 rounded-lg shadow-md">
          <h3 className="text-2xl font-semibold text-gray-800 mb-6">Edit Event</h3>
          <form className="space-y-4">
            {/* Event edit form fields */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-lg font-medium text-gray-800">Event Title:</label>
                <input
                  type="text"
                  name="Title"
                  value={currentEvent.Title}
                  onChange={handleChange}
                  className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                  placeholder="Enter event Title"
                />
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-800">Eventdate:</label>
                <input
                  type="date"
                  name="Eventdate"
                  value={currentEvent.Eventdate}
                  onChange={handleChange}
                  className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-lg font-medium text-gray-800">Event Time:</label>
                <input
                  type="time"
                  name="Time"
                  value={currentEvent.Time}
                  onChange={handleChange}
                  className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                />
              </div>
              <div>
                <label className="block text-lg font-medium text-gray-800">Event Day:</label>
                <input
                  type="text"
                  name="Day"
                  value={currentEvent.Day}
                  onChange={handleChange}
                  className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                  placeholder="Enter event Day"
                />
              </div>
            </div>

            <div>
              <label className="block text-lg font-medium text-gray-800">Event Venu:</label>
              <input
                type="text"
                name="Venu"
                value={currentEvent.Venu}
                onChange={handleChange}
                className="w-full p-3 border-2 border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
                placeholder="Enter event Venu"
              />
            </div>

            <div className="mt-6">
              <button
                type="button"
                onClick={handleUpdate}
                className="w-full py-3 bg-indigo-600 text-white text-lg font-semibold rounded-lg hover:bg-indigo-700 focus:outline-none"
              >
                Update Event
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div className="overflow-x-auto bg-white shadow-md rounded-lg">
          <table className="min-w-full table-auto">
            <thead className="bg-indigo-600 text-white">
              <tr>
                <th className="px-6 py-3 text-left">Title</th>
                <th className="px-6 py-3 text-left">Eventdate</th>
                <th className="px-6 py-3 text-left">Time</th>
                <th className="px-6 py-3 text-left">Day</th>
                <th className="px-6 py-3 text-left">Venu</th>
                <th className="px-6 py-3 text-left">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event._id} className="border-t border-gray-200">
                  <td className="px-6 py-4">{event.Title}</td>
                  <td className="px-6 py-4">{event.Eventdate}</td>
                  <td className="px-6 py-4">{event.Time}</td>
                  <td className="px-6 py-4">{event.Day}</td>
                  <td className="px-6 py-4">{event.Venu}</td>
                  <td className="px-6 py-4 text-indigo-600 space-x-2">
                    <button
                      onClick={() => handleEdit(event)}
                      className="hover:text-indigo-800 focus:outline-none"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(event._id)}
                      className="text-red-600 hover:text-red-800 focus:outline-none"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ManageEvents;