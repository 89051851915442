import React, { useState } from 'react';
import axios from 'axios';

const AddMember = () => {
  const [memberData, setMemberData] = useState({
    Passcode: '',
    Userimage: null,
    StatusImage: null,
    EmailAddress: '',
    SpouseName: '',
    UAEAddress: '',
    IndianAddress: '',
    Name: '',
    CompanyName: '',
    ChildrenName: '',
    ContactNumber: [
      {
        firstPhoneNumber: '',
        secondPhoneNumber: ''
      }
    ],
    IndiaContactNumber: [
      {
        firstPhoneNumber: '',
        secondPhoneNumber: ''
      }
    ]
  });

  const [imagePreview, setImagePreview] = useState({
    Profile: null,
    Status: null
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setMemberData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleImageChange = (e) => {
    const { name, files } = e.target;
    const file = files[0];

    setMemberData((prevData) => ({
      ...prevData,
      [name]: file
    }));

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreview((prevState) => ({
        ...prevState,
        [name]: reader.result
      }));
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append('Passcode', memberData.Passcode);
      formData.append('Userimage', memberData.Userimage);
      formData.append('StatusImage', memberData.StatusImage);
      formData.append('EmailAddress', memberData.EmailAddress);
      formData.append('SpouseName', memberData.SpouseName);
      formData.append('UAEAddress', memberData.UAEAddress);
      formData.append('IndianAddress', memberData.IndianAddress);
      formData.append('Name', memberData.Name);
      formData.append('CompanyName', memberData.CompanyName);
      formData.append('ChildrenName', memberData.ChildrenName);
      formData.append('ContactNumber1', memberData.ContactNumber[0].firstPhoneNumber);
      formData.append('ContactNumber2', memberData.ContactNumber[0].secondPhoneNumber);
      formData.append('IndiaPhoneNumber1', memberData.IndiaContactNumber[0].firstPhoneNumber);
      formData.append('IndiaPhoneNumber2', memberData.IndiaContactNumber[0].secondPhoneNumber);

      const response = await axios.post('https://dubaichurch.xyz/current-member', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log(response.data);
      alert('Member added successfully!');

      setMemberData({
        Passcode: '',
        Userimage: null,
        StatusImage: null,
        EmailAddress: '',
        SpouseName: '',
        UAEAddress: '',
        IndianAddress: '',
        Name: '',
        CompanyName: '',
        ChildrenName: '',
        ContactNumber: [
          {
            firstPhoneNumber: '',
            secondPhoneNumber: ''
          }
        ],
        IndiaContactNumber: [
          {
            firstPhoneNumber: '',
            secondPhoneNumber: ''
          }
        ]
      });
      setImagePreview({
        Profile: null,
        Status: null
      });
    } catch (error) {
      console.error('Error adding member:', error);
      alert(error.response?.data?.message || 'Error adding member. Please try again.');
    }
  };

  return (
    <div className="bg-white shadow-md rounded-lg p-8">
      <h1 className="text-3xl font-bold mb-6">Add New Member</h1>

      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Name & Passcode */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="Name" className="block text-sm font-medium text-gray-700">Name</label>
            <input
              type="text"
              id="Name"
              name="Name"
              value={memberData.Name}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="Passcode" className="block text-sm font-medium text-gray-700">Passcode</label>
            <input
              type="text"
              id="Passcode"
              name="Passcode"
              value={memberData.Passcode}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        {/* Profile Picture & Status Image */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="Userimage" className="block text-sm font-medium text-gray-700">Profile Picture</label>
            <input
              type="file"
              id="Userimage"
              name="Userimage"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="StatusImage" className="block text-sm font-medium text-gray-700">Status Image</label>
            <input
              type="file"
              id="StatusImage"
              name="StatusImage"
              accept="image/*"
              onChange={handleImageChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Email Address & Spouse Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="EmailAddress" className="block text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              id="EmailAddress"
              name="EmailAddress"
              value={memberData.EmailAddress}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              required
            />
          </div>
          <div>
            <label htmlFor="SpouseName" className="block text-sm font-medium text-gray-700">Spouse Name</label>
            <input
              type="text"
              id="SpouseName"
              name="SpouseName"
              value={memberData.SpouseName}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* UAE Address & Indian Address */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="UAEAddress" className="block text-sm font-medium text-gray-700">UAE Address</label>
            <textarea
              id="UAEAddress"
              name="UAEAddress"
              value={memberData.UAEAddress}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            ></textarea>
          </div>
          <div>
            <label htmlFor="IndianAddress" className="block text-sm font-medium text-gray-700">Indian Address</label>
            <textarea
              id="IndianAddress"
              name="IndianAddress"
              value={memberData.IndianAddress}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              rows="3"
            ></textarea>
          </div>
        </div>

        {/* Company Name & Children Name */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="CompanyName" className="block text-sm font-medium text-gray-700">Company Name</label>
            <input
              type="text"
              id="CompanyName"
              name="CompanyName"
              value={memberData.CompanyName}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="ChildrenName" className="block text-sm font-medium text-gray-700">Children Name</label>
            <input
              type="text"
              id="ChildrenName"
              name="ChildrenName"
              value={memberData.ChildrenName}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Contact Number */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="ContactNumber1" className="block text-sm font-medium text-gray-700">Contact Number 1</label>
            <input
              type="text"
              id="ContactNumber1"
              name="ContactNumber[0].firstPhoneNumber"
              value={memberData.ContactNumber[0].firstPhoneNumber}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="ContactNumber2" className="block text-sm font-medium text-gray-700">Contact Number 2</label>
            <input
              type="text"
              id="ContactNumber2"
              name="ContactNumber[0].secondPhoneNumber"
              value={memberData.ContactNumber[0].secondPhoneNumber}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* India Contact Number */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label htmlFor="IndiaPhoneNumber1" className="block text-sm font-medium text-gray-700">India Phone Number 1</label>
            <input
              type="text"
              id="IndiaPhoneNumber1"
              name="ndiaContactNumber[0].firstPhoneNumber"
              value={memberData.IndiaContactNumber[0].firstPhoneNumber}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div>
            <label htmlFor="IndiaPhoneNumber2" className="block text-sm font-medium text-gray-700">India Phone Number 2</label>
            <input
              type="text"
              id="IndiaPhoneNumber2"
              name="IndiaContactNumber[0].secondPhoneNumber"
              value={memberData.IndiaContactNumber[0].secondPhoneNumber}
              onChange={handleChange}
              className="mt-2 block w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
        </div>

        {/* Profile & Status Image Preview */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            {imagePreview.Profile && (
              <div className="mt-4">
                <img src={imagePreview.Profile} alt="Profile Preview" className="w-32 h-32 object-cover rounded-full" />
              </div>
            )}
          </div>
          <div>
            {imagePreview.Status && (
              <div className="mt-4">
                <img src={imagePreview.Status} alt="Status Preview" className="w-32 h-32 object-cover rounded-full" />
              </div>
            )}
          </div>
        </div>

        {/* Submit Button */}
        <div className="mt-6">
          <button
            type="submit"
            className="w-full bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddMember;